<template>
    <div class="base-container">
        <GiveMark2 :isTypeFu="2" />
    </div>
</template>

<script>
    import GiveMark2 from "../../components/GiveMark2";

    export default {
        name: "reviewed",
        components: {GiveMark2},
        data() {
            return {};
        },
        mounted() {},
        methods: {},
    };
</script>

<style scoped lang="scss">
    @import "../../assets/css/giveMark";
</style>
